var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view-users"},[_c('b-container',[_c('b-row',{staticClass:"mt-3"},[_c('b-col',[_c('h1',[_vm._v(_vm._s(_vm.$t('users_header')))]),_c('p',[_vm._v(_vm._s(_vm.$t('users_introduction')))])])],1),_c('b-row',{staticClass:"mt-1 mb-3"},[(_vm.users.length > 1)?_c('b-col',{staticClass:"text-right"},[(_vm.loading && _vm.usersLoaded)?_c('b-spinner',{staticClass:"mr-3",attrs:{"small":""}}):_vm._e(),_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":_vm.generateListFile}},[_vm._v(" "+_vm._s(_vm.$t('users_download_emails')))]),_c('router-link',{staticClass:"btn btn-success",attrs:{"to":{ name: 'user-new' },"title":_vm.$t('users_new_user_title')}},[_vm._v(_vm._s(_vm.$t('users_new_user')))])],1):_vm._e()],1),_c('b-row',[_c('b-col',[_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.users,"fields":_vm.usersTableFields,"per-page":_vm.usersTablePerPage,"current-page":_vm.usersTableCurrentPage,"show-empty":true,"empty-text":_vm.$t('users_no_users')},scopedSlots:_vm._u([{key:"cell(name)",fn:function(data){return [(_vm.$store.state.user.userId === data.item.userId)?_c('span',[_vm._v(_vm._s(data.value))]):_c('router-link',{attrs:{"to":{
                name: 'user-edit',
                params: { userId: data.item.userId },
              }}},[_vm._v(_vm._s(data.value))])]}},{key:"cell(role)",fn:function(data){return [_vm._v(_vm._s(_vm.$t(data.value)))]}},{key:"cell(client)",fn:function(data){return [(data.value)?_c('router-link',{attrs:{"to":{
                name: 'client-edit',
                params: { clientId: data.value.id },
              }}},[_vm._v(_vm._s(data.value.name))]):_c('span',[_vm._v("-")])]}},{key:"cell(isActive)",fn:function(data){return [(_vm.$store.state.user.userId != data.item.userId)?_c('Icon',{staticStyle:{"height":"1.5em","cursor":"pointer"},attrs:{"icon":data.value ? 'interface:unlocked' : 'interface:locked',"stroke":"","fill":"","title":_vm.$t(
                  data.value
                    ? 'users_title_user_unlocked'
                    : 'users_title_user_locked'
                ),"emit":"clicked"},on:{"clicked":function($event){return _vm.confirmSetUserLock(data.item.userId, data.value)}}}):_vm._e()]}},{key:"cell(delete)",fn:function(data){return [(_vm.$store.state.user.userId != data.item.userId)?_c('Icon',{staticStyle:{"height":"1.5em","cursor":"pointer"},attrs:{"icon":"interface:delete","stroke":"","fill":"","title":_vm.$t('users_title_user_delete'),"emit":"clicked"},on:{"clicked":function($event){return _vm.confirmDeleteUser(data.item.userId)}}}):_vm._e()]}},{key:"empty",fn:function(scope){return [_c('div',{staticClass:"text-center"},[(_vm.loading)?_c('span',[_c('b-spinner')],1):_c('span',[_vm._v(_vm._s(scope.emptyText))])])]}}])}),(_vm.users.length > _vm.usersTablePerPage)?_c('b-pagination',{attrs:{"total-rows":_vm.users.length,"per-page":_vm.usersTablePerPage,"align":"center"},model:{value:(_vm.usersTableCurrentPage),callback:function ($$v) {_vm.usersTableCurrentPage=$$v},expression:"usersTableCurrentPage"}}):_vm._e()],1)],1)],1),_c('b-modal',{attrs:{"id":"user-confirm-set-lock","title":_vm.$t(
        _vm.setLock.lock
          ? 'users_modal_confirm_lock_title'
          : 'users_modal_confirm_unlock_title',
        {
          userName: _vm.setLock.user.name,
        }
      )},on:{"ok":_vm.doSetUserLock}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t( _vm.setLock.lock ? 'users_modal_confirm_lock_text' : 'users_modal_confirm_unlock_text', { userName: _vm.setLock.user.name, } ))+" ")])]),_c('b-modal',{attrs:{"id":"user-confirm-delete","title":_vm.$t('users_modal_confirm_delete_title', {
        userName: _vm.deleteUser.name,
      }),"ok-variant":"danger","ok-title":_vm.$t('common_delete')},on:{"ok":_vm.doDeleteUser}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('users_modal_confirm_delete_text', { userName: _vm.deleteUser.name, }))+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }