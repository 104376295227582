/**
 * List users.
 */
import { apiDelete, apiGet, apiPut } from '@/util/api';
import Icon from '@/icons/Icon.vue';

export default {
  name: 'Users',
  components: {
    Icon,
  },
  data() {
    return {
      users: [],
      usersLoaded: false,
      loading: true,
      usersTablePerPage: 10,
      usersTableCurrentPage: 1,
      usersTableFields: [
        {
          key: 'name',
          label: this.$t('users_list_user_name'),
        },
        {
          key: 'client',
          label: this.$t('users_list_user_client'),
        },
        {
          key: 'role',
          label: this.$t('users_list_user_role'),
        },
        {
          key: 'isActive',
          label: '',
          class: 'table-user-control',
        },
        {
          key: 'delete',
          label: '',
          class: 'table-user-control',
        },
      ],
      setLock: {
        lock: true,
        user: {},
      },
      deleteUser: {},
    };
  },
  async created() {
    // Get the list of users from the API.
    try {
      this.users = await apiGet('/user');
    } catch (error) {
      this.$bvToast.toast(this.$t('users_error_get_users'), {
        title: this.$t('common_error_title'),
        variant: 'danger',
      });
    } finally {
      this.loading = false;
      this.usersLoaded = true;
    }
  },
  methods: {
    /**
     * Show the "set user lock" modal.
     * @param {Number}  userId
     * @param {Boolean} lock
     */
    confirmSetUserLock(userId, lock) {
      this.setLock.lock = lock;
      this.setLock.user = this.users.find(u => {
        return u.userId === userId;
      });
      this.$bvModal.show('user-confirm-set-lock');
    },

    /**
     * Handle the API request which causes a user to be locked or unlocked.
     */
    async doSetUserLock() {
      this.loading = true;
      await apiPut(`/user/${this.setLock.user.userId}`, {
        isActive: !this.setLock.lock,
      })
        .then(() => {
          const user = this.users.find(
            u => u.userId === this.setLock.user.userId,
          );
          user.isActive = !this.setLock.lock;
          this.$bvToast.toast(
            this.$t(
              this.setLock.lock
                ? 'users_success_set_user_lock'
                : 'users_success_set_user_unlock',
              { userName: this.setLock.user.name },
            ),
            {
              title: this.$t('common_success_title'),
              variant: 'success',
            },
          );
        })
        .catch(() => {
          this.$bvToast.toast(
            this.$t(
              this.setLock.lock
                ? 'users_error_set_user_lock'
                : 'users_error_set_user_unlock',
              { userName: this.setLock.user.name },
            ),
            {
              title: this.$t('common_error_title'),
              variant: 'danger',
            },
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * Confirm permanent deletion of a user.
     * @param {Number}  userId
     */
    confirmDeleteUser(userId) {
      this.deleteUser = this.users.find(u => {
        return u.userId === userId;
      });
      this.$bvModal.show('user-confirm-delete');
    },

    /**
     * Handle the API request which deletes the user.
     */
    async doDeleteUser() {
      this.loading = true;
      await apiDelete(`/user/${this.deleteUser.userId}`)
        .then(() => {
          // Remove the user from the list
          const userIndex = this.users.findIndex(
            u => u.userId === this.deleteUser.userId,
          );
          this.users.splice(userIndex, 1);
          this.$bvToast.toast(
            this.$t('users_success_delete_user', {
              userName: this.deleteUser.name,
            }),
            {
              title: this.$t('common_success_title'),
              variant: 'success',
            },
          );
        })
        .catch(() => {
          this.$bvToast.toast(
            this.$t('users_error_delete_user', {
              userName: this.deleteUser.name,
            }),
            {
              title: this.$t('common_error_title'),
              variant: 'danger',
            },
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * Create and init download of email list file.
     */
    async generateListFile() {
      this.loading = true;

      if (this.users.length > 0 && !this.users[0].email) {
        this.users = await apiGet('/user?email=true');
      }

      // Filters out current user's email
      const output =
        this.users
          .filter(user => user.userId !== this.$store.state.user.userId)
          .filter(user => user.email)
          .map(user => user.email)
          .join('\r\n') + '\r\n';

      const downloadLink = document.createElement('a');
      downloadLink.setAttribute(
        'href',
        'data:text/plain;charset=utf-8,' + encodeURIComponent(output),
      );
      downloadLink.setAttribute('download', 'emails.txt');
      downloadLink.click();
      downloadLink.remove();

      this.loading = false;
    },
  },
};
